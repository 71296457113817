<template>
  <div class="un-validata"
    v-if="visible">
    <label class="status"
      v-if="message !== ''">校验结果：{{message}}</label>

    <el-table :data="dataList"
      border
      height="90%">
      <el-table-column type="index"
        width="50"></el-table-column>
      <el-table-column prop="plate"
        label="设备名称"
        width="100"></el-table-column>
      <el-table-column prop="plateColor"
        label="车牌颜色"
        width="100"></el-table-column>
      <el-table-column prop="terminalNo"
        label="终端编号"
        width="120"></el-table-column>
      <el-table-column prop="sim"
        label="SIM卡号"
        width="120"></el-table-column>
      <el-table-column prop="terminalType"
        label="终端类型"
        width="120"></el-table-column>
      <el-table-column prop="verifiInfo"
        label="失败原因"></el-table-column>
    </el-table>

    <div class="steps-btn">
      <!-- <el-button size="small" type="primary" @click="rebackAfter">上一步</el-button> -->
      <el-button size="small"
        type="primary"
        style="position: absolute;right: 30px;"
        @click="downLoadClick">下载</el-button>
      <el-button size="small"
        type="primary"
        @click="Reback">返回</el-button>
    </div>
  </div>
</template>

<script>
import { exportErrorData } from '@/api/getManagementUpload.js';
import moment from 'dayjs';
import { saveAsFile } from '@/utils/saveAsFile';

export default {
  name: 'checkDataModal',
  props: {
    visible: { // 是否显示
      type: Boolean,
      default: false
    },
    data: { // 不合格数据
      type: Array,
      default: []
    },
    message: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      dataList: [],
    };
  },

  watch: {
    data: {
      handler(val) {
        this.dataList = this.data;
      }
    }
  },

  methods: {
    // 下载错误数据
    async downLoadClick() {
      const result = await exportErrorData();
      let urls = URL.createObjectURL(result);
      if (result.size != 0) {
        saveAsFile(
          urls,
          `${ moment(new Date()).format("YYYYMMDDHHmmss") }.xls`
        );
      }
    },

    // 返回上级
    Reback() {
      this.$emit('on-back');
    }
  }

}
</script>
<style lang="scss" scoped>
.un-validata {
  width: 100%;
  height: calc(100% - 80px);
  .status {
    width: 100%;
    height: 40px;
    display: inline-block;
    background: #fff8f3;
    border: 1px solid #ffcfab;
    font-size: 14px;
    color: #ff872d;
    line-height: 40px;
    padding: 0 15px;
    box-sizing: border-box;
    margin: 0 0 15px 0;
  }
  .steps-btn {
    width: 100%;
    margin: 20px 0;
  }
}
</style>