import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';
const axios = createHttp(appConfig.base2GUrl)

/**
 * 获取用户后台是否还有未分配的车辆
 * @param {JSON} val 
 */
export const getUserOper = (val) => { return axios.get('batchImport/getUserOper.do', { params: val }) }


/**
 * 获取导入数据
 * @param {JSON} val 
 */
export const getImports = (val) => { return axios.get('batchImport/getImports.do', { params: val }) }


/**
 * 删除导入数据
 * @param {JSON} val 
 */
export const delImport = (val) => { return axios.get('batchImport/delImport.do', { params: val }) }

/**
 * 更新冲突的车辆数据
 * @param {JSON} val 
 */
export const updateImports = (val) => { return axios.get('uploadExcel/updateImports.do', { params: val }) }

/**
 * 上传文件
 * @param {FormData} formData 
 * @param {JSON} config 
 */
export const upload = (formData, config) => { return axios.post('batchImport/importVehicles.do', formData, config) }

/** 
 * 车辆批量导入
 *  @param {FormData} formData 
 * @param {JSON} config 
 */
export const taxiDataReport = (formData, config) => { return axios.post('/uploadExcel/TaxiDataReport.do', formData, config) }

//-----------------------------------
/**
 * 获取导入监控值班记录数据
 * @param {JSON} val 
 */
export const getImports2 = (val) => { return axios.get('monitorrecordtemp/listMonitorRrecordTemp.do',{params: val})}

/**
 * 删除导入监控值班记录临时数据
 * @param {JSON} val 
 */
export const delImport2 = (val) => { return axios.post('monitorrecordtemp/deleteMonitorRrecordTemp.do', { params: val }) }

/**
 * 删除单条导入监控值班记录临时数据
 * @param {JSON} val
 */
export const delSingle2 = (val) => { return axios.get('monitorrecordtemp/updateMonitorRrecordTemp.do', { params: val }) }

/**
 * 导入值班记录表
 * @param {FormData} formData 
 * @param {JSON} config 
 */
export const upload2 = (formData, config) => { return axios.post('monitorrecordtemp/importMonitorrecord.do', formData, config) }

/**
 * 更新监控值班数据
 * @param {JSON} val 
 */
export const updateDutyMonitoring = (val) => { return axios.post('monitorrecordtemp/updateMonitorRrecordTemp.do', { monitoRrecordTemp: val }) }

/**
 * 保存监控值班记录
 * @param {JSON} val 
 */
export const saveDutyMonitoring = (val) => { return axios.get('monitorrecordtemp/saveMonitorRrecordTemp.do', { params: val }) }

/**
 * 获取用户后台是否还有缓存数据
 * @param {JSON} val 
 */
export const getUserOper2 = () => { return axios.get('monitorrecordtemp/initMonitorRecordTemp.do')}

/**
* 导出错误数据
*/
export const exportErrorData = (url, val) => { return axios.get('batchImport/exportErrorData.do', { responseType: 'blob', params: val }); };
