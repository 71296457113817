<template>
  <div v-if="visible">
    <div class="select-group">
      <label class="required">选择车组</label>
      <SelectGroupTree
        style="width:230px"
        :inputVehicle="false"
        :singleGroup="true"
        :multipleProp="{
          noGroup: true, // 是否可选车组
          groupCheckBox: false, //车组多选
          vehicleCheckBox: false, //车辆多选
        }"
        @on-select="onSelect"
        @select-group="dbConfirm"
        @clear="onClear"
      ></SelectGroupTree>
    </div>
    <el-upload
      ref="upload"
      class="avatar-uploader"
      accept=".xls"
      action="https://videoserver.car900.com/"
      :before-upload="beforeAvatarUpload"
      :show-file-list="file !== null"
    >
      <i class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>

    <div class="upload-btn">
      <el-button
        type="primary"
        @click="uploadFile"
        :disabled="file === null"
        :loading="loadingStatus"
      >{{ loadingStatus ? '上传中...' : '上传' }}</el-button>
    </div>

    <div class="upload-tip">
      <label>
        <Iconfont name="icon-xiaotishi"></Iconfont>小技巧:
      </label>
      <ul>
        <li>
          首次使用,请点击下载
          <a target="_blank" href="/resource/批量导入模板.xls">最新导入模板</a>!一次批量提交的车辆数不能超过1000台，如果超过限制将提交失败！
        </li>
        <li>如果系统提示您有未完成的任务，可能是上次批量导入未完成或此时其他电脑正在用您的账户进行批量导入。请避免您的账号,同一时间,使用不同电脑进行批量导入！！</li>
        <li style="color:#FD9649">视频类设备终端类型请选择：GB-1078，非视频类设备终端类型请选择：GPRS-部标！！</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { upload } from '@/api/getManagementUpload.js';
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';

export default {
  name: 'uploadModal',
  components: {
    SelectGroupTree
  },
  props: {
    visible: { // 是否显示
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loadingStatus: false, // 状态
      file: null,
      groupId: 0, // 车组id
    };
  },

  methods: {
    onClear() {
      this.groupId = null;
    },
    //车组搜索框选择
    onSelect(node) {
      this.groupId = node.groupId;
    },

    // 选择车组
    dbConfirm(node) {
      this.groupId = node.groupId;
    },

    //上传文件之前的钩子，参数为上传的文件
    beforeAvatarUpload(file) {
      if (!this.groupId) {
        this.$message.error('请选择车组导入');
        return false;
      }

      this.file = file;
      // return false;
    },
    // 上传文件
    async uploadFile() {
      this.loadingStatus = true;
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('groupId', this.groupId);
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      const result = await upload(formData, config);
      this.loadingStatus = false;
      if (!result.flag) return this.$message.warning(result.msg);

      this.file = null;
      this.$message.success(result.msg);

      this.$emit('on-success', result.msg);
    },
  }
}
</script>

<style lang="scss" scoped>
.avatar-uploader {
  width: 70%;
  height: 290px;
  border: 1px dashed #d9d9d9;
  margin: 30px auto 40px;
  text-align: center;
  line-height: 290px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.upload-btn {
  width: 70%;
  margin: 10px auto 40px;
  text-align: right;
}

.upload-tip {
  width: 70%;
  height: 154px;
  margin: 10px auto;
  background: #f2f9ff;
  border: 1px solid #bbdeff;
  padding: 12px;
  & > label {
    color: #1890ff;
    font-size: 14px;
  }
  & > ul {
    color: #3e3a39;
    font-size: 14px;
    line-height: 25px;
    margin: 0;
  }
}

.select-group {
  width: 70%;
  height: 32px;
  margin: 20px auto 0;
  display: flex;
  & > label {
    line-height: 30px;
    width: 76px;
    font-size: 14px;
  }
}

.required::before {
  content: '*';
  color: red;
  font-weight: bold;
  margin-right: 1px;
  font-size: 20px;
}
</style>