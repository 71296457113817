<template>
  <div class="batch-import">
    <!-- <el-steps :active="activeSteps">
      <el-step title="上传文件"></el-step>
      <el-step title="校验数据"></el-step>
      <el-step title="确认导入"></el-step>
    </el-steps>-->

    <uploadModal :visible="setps===1" @on-success="verifyData" />

    <checkDataModal
      :visible="setps===2"
      :data="dataList"
      :message="message"
      @on-back="rebackClick"
    />
  </div>
</template>

<script>
import uploadModal from './uploadModal';
import checkDataModal from './checkDataModal';
import { getImports, getUserOper, delImport } from '@/api/getManagementUpload.js';
import { mapState } from 'vuex';

export default {
  // `name`与路由保持一致
  name: 'BatchImport',
  components: {
    uploadModal,
    checkDataModal,
  },
  data() {
    return {
      activeSteps: 0, //上传步骤  
      loadingStatus: false, // 状态
      file: null,

      addFlag: 1,
      dataList: [],
      setps: 1, // 1:上传文件 2：下载错误数据
      message: "",
    };
  },
  mounted() {
    this.getUserUploadStatus();
  },
  computed: {
    ...mapState(['userInfo']),
  },
  methods: {
    // 返回上级
    rebackClick() {
      this.setps = 1;
      this.getUserUploadStatus();
    },

    // 查询该用户是否有未完成进度
    async getUserUploadStatus() {
      const result = await getUserOper({ userId: this.userInfo.userId });

      if (result.flag) return;

      this.$confirm('你有未完成的进度, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.setps = 2;
        this.verifyData();

      }).catch(() => {
        this.deleteUpload();
      });
    },

    // 删除未完成进度
    async deleteUpload() {
      const result = await delImport({ userId: this.userInfo.userId });

      if (!result.flag) return this.$message.error(result.msg);

      this.$message.success(result.msg);
    },

    //校验数据
    async verifyData(msg) {
      this.message = msg;
      this.setps = 2;
      const result = await getImports({ addFlag: this.addFlag });
      this.dataList = result.obj;
    },
  }
}
</script>
<style lang="scss" scoped>
.batch-import {
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 15px 20px;
  .el-steps {
    width: 70%;
    margin: 10px auto 20px;
  }
}
</style>